import * as React from "react"
import { Box } from "@mui/material";
import { StandardPageContainer } from "../components/reviewskew/standardpage/StandardPageContainer";


const Support = () => {
  return (
    <StandardPageContainer title="Support - Review Skew">
      <Box textAlign="center">
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdeCVynWm7Rral-ltR_hj1PBbBgahe4PDeJaoBaTcyfbxED4w/viewform?embedded=true" width="640" height="711" frameBorder="0" marginHeight={0} marginWidth={0}>Loading…</iframe>
      </Box>
    </StandardPageContainer>
  )
}

export default Support
